<template>
  <div class="loading-overlay">
    <div class="loading-overlay-backdrop"></div>
    <div class="loading-overlay-dialog">
      <div class="loading-overlay-body">
        <font-awesome icon="spinner" size="10x" spin />
      </div>
    </div>
  </div>  
</template>
<script>
export default {
  name: "LoadingOverlay",
}
</script>
<style scoped>
.loading-overlay {
  position: absolute;
  z-index: 9999;
}
.loading-overlay-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
.loading-overlay-body {
  position: relative;
  display: flex;
  margin: auto;
  width: auto;
  min-height: 100%;
  justify-content: center;
  align-items: center;
}
.loading-overlay-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  background: #000;
  opacity: .5;
  width: 100vw;
  height: 100vh;
}
</style>