<template>
  <div class="container-fluid">
    <LoadingOverlay v-show="isLoading" />
    <ProductListNavTab v-if="!isBundleUrl" />
    <BundleNavTab v-else />
    <div class="p-lg-2">
      <h2 class="roboto bold">{{noEdit ? 'Detail' : productId ? 'Edit' : 'Add'}} {{isBundleUrl ? 'Bundle' : 'Product'}}</h2>
      <b-form ref="form" class="pt-5" @submit.prevent>
        <h2 class="subtitle roboto bold">What you're selling</h2>
        <hr />
        <!-- Product name -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">{{isBundleUrl ? 'Bundle' : 'Product'}} Name <b style="color: red">*</b></div>
          <div class="col-md-7">
            <InputText
              :disabled="noEdit"
              type="text"
              v-model="$v.formData.name.$model"
              :placeholder="`Insert ${isBundleUrl ? 'Bundle' : 'Product'} Name`"
              info="Maximum 50 characters."
              maxlength="50"
            />
          </div>
          <div class="col-md-3">
            <small class="text-grey">
              {{isBundleUrl ? 'Bundle' : 'Product'}} name may only be changed if it has not been sold.
            </small>
          </div>
        </div>
        <!-- Category -->
        <div class="row" :class="{'d-none' : isBundleUrl}">
          <div class="col-md-2 pt-2">Category <b style="color: red">*</b></div>
          <div class="col-md-3">
            <InputSelect
              :disabled="noEdit"
              v-model="$v.formData.category_id.$model"
              :options="categories"
              placeholder="Select Category"
              @input="getSubCategory"
            />
          </div>
          <div class="col-md-3">
            <InputSelect
              :disabled="noEdit"
              v-model="$v.formData.sub_category_id.$model"
              :options="sub_categories"
              placeholder="Select Sub Category 1"
              @input="getSubSubCategory"
            />
          </div>
          <div class="col-md-3">
            <InputSelect
              :disabled="noEdit"
              v-model="$v.formData.sub_sub_category_id.$model"
              :options="sub_sub_categories"
              placeholder="Select Sub Category 2"
            />
          </div>
        </div>
        <!-- Brand -->
        <div class="row">
          <div class="col-md-2 pt-2">Brand <b style="color: red">*</b></div>
          <div class="col-md-3">
            <InputSelect
              :disabled="noEdit"
              v-model="$v.formData.brand_id.$model"
              :options="brands"
              placeholder="Select Your Brand"
            />
          </div>
        </div>
        <div class="shadow mb-5">&nbsp;</div>
        <h2 class="subtitle roboto bold">{{isBundleUrl ? 'Bundle' : 'Product'}} Image <b style="color: red;">*</b></h2>
        Insert {{isBundleUrl ? 'bundle' : 'product'}} images. File size must be &le; 512 KB &amp; exactly 2000&times;2000 px resolution.
        <hr/>
        <div class="row pb-3" :class="{'d-none' : !imgSrc}">
          <div class="col-md-8">
            <vue-cropper
              ref="cropper"
              :src="imgSrc"
              alt="Source"
              :responsive="true"
              :viewMode="2"
              :aspect-ratio="1 / 1"
              :zoomable="false"
              :minContainerHeight="getContainerWidth('.cropper-container')"
              :autoCropArea="1"
              :minCropBoxWidth="200"
              :minCropBoxHeight="200"
              @cropend="setImage(imgIndex); cropend(imgIndex)"
            />
          </div>
        </div>
        <input :disabled="noEdit" id="img-input-0" type="file" visibility="hidden" accept="image/jpeg" @input="setPreviewImage($event, 0)" />
        <input :disabled="noEdit" id="img-input-1" type="file" visibility="hidden" accept="image/jpeg" @input="setPreviewImage($event, 1)" />
        <input :disabled="noEdit" id="img-input-2" type="file" visibility="hidden" accept="image/jpeg" @input="setPreviewImage($event, 2)" />
        <input :disabled="noEdit" id="img-input-3" type="file" visibility="hidden" accept="image/jpeg" @input="setPreviewImage($event, 3)" />
        <input :disabled="noEdit" id="img-input-4" type="file" visibility="hidden" accept="image/jpeg" @input="setPreviewImage($event, 4)" />
        <div class="img-input text-nowrap overflow-auto">
          <img @click="clickImgInput(0)" id="img-preview-0" :src="item ? checkImg(item.product_details[0].product_images[0].medium_image_url) : require('@/assets/img/icon-upload-mainimg.png')" />
          <img @click="clickImgInput(1)" id="img-preview-1" :src="item ? checkImg(item.product_details[0].product_images[1].medium_image_url) : require('@/assets/img/icon-upload.png')" />
          <img @click="clickImgInput(2)" id="img-preview-2" :src="item ? checkImg(item.product_details[0].product_images[2].medium_image_url) : require('@/assets/img/icon-upload.png')" />
          <img @click="clickImgInput(3)" id="img-preview-3" :src="item ? checkImg(item.product_details[0].product_images[3].medium_image_url) : require('@/assets/img/icon-upload.png')" />
          <img @click="clickImgInput(4)" id="img-preview-4" :src="item ? checkImg(item.product_details[0].product_images[4].medium_image_url) : require('@/assets/img/icon-upload.png')" />
        </div>
        <div class="shadow mb-5">&nbsp;</div>
        <h2 class="subtitle roboto bold">{{isBundleUrl ? 'Bundle' : 'Product'}} Detail</h2>
        Let people know about {{isBundleUrl ? 'this bundle' : 'your product'}}
        <hr/>
        <template v-if="!isBundleUrl">
          <!-- BP Exclusive -->
          <div class="row align-items-center mb-3 no-gutters">
            <div class="col-md-2 pt-1">BP Exclusive</div>
            <div class="col-auto ml-1">
              <b-form-checkbox :disabled="noEdit" switch size="lg" v-model="$v.formData.bp_exclusive.$model" />
            </div>
            <div class="col pt-1"><small class="text-grey">Set enabled to only allow purchase by BP</small></div>
          </div>
          <!-- Is Purchasable -->
          <div class="row align-items-center mb-3 no-gutters">
            <div class="col-md-2 pt-1">Purchasable <b style="color: red;">*</b></div>
            <div class="col-auto ml-1">
              <b-form-checkbox :disabled="noEdit" switch size="lg" v-model="$v.formData.status_purchaseable.$model" />
            </div>
            <div class="col"><small class="text-grey">Disable if only obtainable via bundle</small></div>
          </div>
          <!-- Catalog Visible -->
          <div class="row align-items-center mb-3 no-gutters">
            <div class="col-md-2 pt-1">Show in catalog</div>
            <div class="col-auto ml-1">
              <b-form-checkbox :disabled="noEdit" switch size="lg" v-model="$v.formData.catalog_visible.$model" />
            </div>
            <div class="col pt-1"><small class="text-grey">Disable to hide this product from any catalogs</small></div>
          </div>
          <!-- Bundling to All Product -->
          <div v-if="!formData.catalog_visible" class="row align-items-center mb-3 no-gutters">
            <div class="col-md-2 pt-1">Bundling to All Products</div>
            <div class="col-auto ml-1">
              <b-form-checkbox :disabled="noEdit" switch size="lg" v-model="formData.bundle_check_all_product" />
            </div>
            <div class="col pt-1"><small class="text-grey">If you enable this, you cannot set bundling related category</small></div>
          </div>
          <!-- Bundling Related Category -->
          <div v-if="!formData.catalog_visible && !formData.bundle_check_all_product" class="row align-items-center mb-3 no-gutters">
            <div class="col-md-2 pt-1">Bundling to Related Category</div>
            <div class="col-md-7 ml-1">
              <div class="category-tag-container" :class="{ 'disabled' : noEdit }">
                <div class="tag" v-for="category in selected_bundle_category_list" :key="category.id">
                  <span>{{ category.name }}</span><fawesome-pro variant="fal" icon="times" class="delete-tag" @click.native="removeBundleRelatedCategory(category)" />
                </div>
                <input type="text" v-model="filterBundleCategory" placeholder="Type category name" />
                <div class="category-options">
                  <div v-for="category in filteredBundleCategory" :key="category.id" @click="addBundleRelatedCategory(category)">
                    {{ category.name }}
                  </div>
                  <div v-if="filteredBundleCategory.length == 0" class="no-hover">
                    {{ `"${filterBundleCategory}" does not exist or may have been added` }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <!-- Price -->
        <div class="row mb-2 align-items-center">
          <div class="col-md-2">Sell Price <b style="color: red;">*</b></div>
          <div class="col-md-4">
            <InputText
              :disabled="noEdit || formData.bundle_dynamic_price"
              v-model="$v.formData.price.$model"
              type="number"
              maxlength="9"
              @keypress="preventDotInput"
            />
          </div>
          <template v-if="isBundleUrl">
            <div class="col-auto">Dynamic Price</div>
            <div class="col-auto pb-1">
              <b-form-checkbox :disabled="noEdit" switch size="lg" v-model="$v.formData.bundle_dynamic_price.$model" />
            </div>
          </template>
        </div>
        <!-- Discount -->
        <div class="row">
          <div class="col-md-2 pt-2">Discount</div>
          <div class="col-6 col-lg-3">
            <InputText
              v-model="$v.formData.discount.$model"
              type="number"
              maxlength="9"
              :disabled="noEdit || formData.discount_type == 0"
              @keypress="preventDotInput($event, formData.discount_type == 1)"
            />
          </div>
          <div class="col col-lg-5">
            <InputSelect
              :disabled="noEdit"
              v-model="$v.formData.discount_type.$model"
              :options="[
                {
                  id: 0,
                  name: 'No Discount',
                },
                {
                  id: 1,
                  name: 'Nominal',
                },
                {
                  id: 2,
                  name: 'Percentage',
                },
              ]"
            />
          </div>
        </div>
        <!-- Discount Datetime -->
        <div class="row mb-2" :class="{'d-none' : formData.discount_type == 0}">
          <div class="col-6 col-md-3 offset-md-2">
            <InputDate
              :disabled="noEdit"
              v-model="$v.formData.from_date.$model"
              :min="formatDate(new Date)"
              :required="true"
              :resetButton="true"
            />
            <InputText
              id="from-time"
              :disabled="noEdit"
              v-model="$v.formData.from_time.$model"
              type="time"
            />
          </div>
          <div class="col-6 col-md-3">
            <InputDate
              :disabled="noEdit || !formData.from_date"
              v-model="$v.formData.to_date.$model"
              :min="formData.from_date"
              :required="true"
              :resetButton="true"
            />
            <InputText
              id="to-time"
              :disabled="noEdit || !formData.from_time"
              v-model="$v.formData.to_time.$model"
              type="time"
            />
          </div>
          <div class="col-6 col-md-3 offset-md-2">
            <button type="button" class="mt-2 btn btn-danger" @click="resetDiscountDateRange" :disabled="!formData.from_date && !formData.to_date && !formData.from_time && !formData.to_time">Reset Discount Date Range</button>
          </div>
        </div>
        <!-- Reseller Commission -->
        <!-- <div class="row" v-if="$store.getters.getMerchantData.merchant_type == 2">
          <div class="col-md-2 pt-2">Bus. Partner Commission</div>
          <div class="col-5 col-lg-2">
            <InputText
              v-model="$v.formData.reseller_commission_value.$model"
              type="number"
              maxlength="9"
              :disabled="noEdit || formData.reseller_commission_type == 0"
            />
          </div>
          <div class="col col-lg-5">
            <InputSelect
              :disabled="noEdit"
              v-model="$v.formData.reseller_commission_type.$model"
              :options="[
                {
                  id: 0,
                  name: 'No Commission',
                },
                {
                  id: 1,
                  name: 'Fixed Value',
                },
                {
                  id: 2,
                  name: 'Percentage',
                },
              ]"
            />
          </div>
        </div> -->
        <!-- Reseller Discount -->
        <!-- <div class="row" v-if="$store.getters.getMerchantData.merchant_type == 2">
          <div class="col-md-2 pt-2">Bus. Partner Discount</div>
          <div class="col-5 col-lg-2">
            <InputText
              v-model="$v.formData.reseller_discount_value.$model"
              type="number"
              maxlength="9"
              :disabled="noEdit || formData.reseller_discount_type == 0"
            />
          </div>
          <div class="col col-lg-5">
            <InputSelect
              :disabled="noEdit"
              v-model="$v.formData.reseller_discount_type.$model"
              :options="[
                {
                  id: 0,
                  name: 'No Bus. Partner Discount',
                },
                {
                  id: 1,
                  name: 'Fixed Value',
                },
                {
                  id: 2,
                  name: 'Percentage',
                },
              ]"
            />
          </div>
        </div> -->
        <div class="row no-gutters">
          <div class="col-md-2 pt-2">Preorder Duration</div>
          <div class="col-3 col-lg-1 ml-1">
            <InputText
              v-model="$v.formData.ready_stock_duration.$model"
              type="number"
              maxlength="2"
              :disabled="noEdit"
              @keypress="preventDotInput"
            />
          </div>
          <div class="col-auto ml-2 pt-2">
            day(s)
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-md-10 offset-md-2">
            <small class="text-grey">
              Your item will be ready to be shipped
            </small>
          </div>
        </div>
        <template v-if="formData.ready_stock_duration > 0">
          <div class="row align-items-center mb-2 no-gutters">
            <div class="col-md-2 pt-1">Batch Date</div>
            <div class="col-auto ml-1">
              <b-form-checkbox :disabled="noEdit" switch size="lg" v-model="$v.formData.batch_po.$model" />
            </div>
            <div class="col"><small class="text-grey">Batches are opened when the switch goes on</small></div>
          </div>
          <div class="row">
            <div class="col col-lg-2 offset-lg-2">
                <InputDate
                :disabled="noEdit || !formData.batch_po"
                v-model="$v.formData.start_date_po.$model"
                :min="formatDate(new Date)"
                :required="true"
              />
            </div>
            <div class="col col-lg-2">
              <InputDate
                :disabled="noEdit || !formData.batch_po || !formData.start_date_po"
                v-model="$v.formData.end_date_po.$model"
                :min="formData.start_date_po"
                :required="true"
              />
            </div>
          </div>
        </template>
        <hr/>
        <!-- Weight -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">Weight (Gr) <b style="color: red;">*</b></div>
          <div class="col-lg-2">
            <InputText
              :disabled="noEdit"
              v-model="$v.formData.weight.$model"
              type="number"
              maxlength="6"
              :classComponent="{
                'is-invalid': $v.formData.weight.$error,
              }"
              :invalidMessage="[
                !$v.formData.weight.required ? 'Weight is required.' : '',
                !$v.formData.weight.between ? 'Weight must be between 1 and 1000.' : '',
              ]"
            />
          </div>
        </div>
        <template v-if="!isBundleUrl">
          <!-- Dimension -->
          <div class="row mb-2 no-gutters">
            <div class="col-md-2 pt-2">Dimension (cm)</div>
            <div class="col-3 col-lg-1 ml-lg-1">
              <InputText
                :disabled="noEdit"
                v-model="$v.formData.dimension1.$model"
                type="number"
                maxlength="9"
                placeholder="cm"
              />
            </div>
            <div class="col col-lg-1 d-flex align-items-center justify-content-center">
              &times;
            </div>
            <div class="col-3 col-lg-1">
              <InputText
                :disabled="noEdit"
                v-model="$v.formData.dimension2.$model"
                type="number"
                maxlength="9"
                placeholder="cm"
              />
            </div>
            <div class="col col-lg-1 d-flex align-items-center justify-content-center">
              &times;
            </div>
            <div class="col-3 col-lg-1">
              <InputText
                :disabled="noEdit"
                v-model="$v.formData.dimension3.$model"
                type="number"
                maxlength="9"
                placeholder="cm"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <!-- Diameter -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Diameter</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="$v.formData.diameter.$model"
                    type="number"
                    maxlength="9"
                    placeholder="cm"
                  />
                </div>
              </div>
              <!-- Number of Diamond -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Number of Diamond</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="$v.formData.number_of_diamond.$model"
                    type="number"
                    maxlength="9"
                  />
                </div>
              </div>
              <!-- Diamond Color -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Diamond Color</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="formData.diamond_color"
                    type="text"
                  />
                </div>
              </div>
              <!-- Diamond Clarity -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Diamond Clarity</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="formData.diamond_clarity"
                    type="text"
                  />
                </div>
              </div>
              <!-- Stamp -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Stamp</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="formData.stamp"
                    type="text"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <!-- Certificate -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Certificate</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="formData.certificate"
                    type="text"
                  />
                </div>
              </div>
              <!-- Kadar -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Kadar</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="$v.formData.kadar.$model"
                    type="number"
                    maxlength="9"
                  />
                </div>
              </div>
              <!-- Weight of Gold -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Weight of Gold</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="$v.formData.weight_of_gold.$model"
                    type="number"
                    maxlength="9"
                  />
                </div>
              </div>
              <!-- Sum Carat of Gold -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Sum Carat of Gold</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="$v.formData.sum_carat_of_gold.$model"
                    type="number"
                    maxlength="9"
                  />
                </div>
              </div>
              <!-- Weight Gold + Diamond -->
              <div class="row mb-2">
                <div class="col-md-4 pt-2">Weight Gold + Diamond</div>
                <div class="col-lg-4">
                  <InputText
                    :disabled="noEdit"
                    v-model="$v.formData.weight_of_gold_plus_diamond.$model"
                    type="number"
                    maxlength="9"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="shadow mb-5 mt-1">&nbsp;</div>
        <h2 class="subtitle roboto bold">{{isBundleUrl ? 'Bundle' : 'Product'}} Description</h2>
        <hr/>
        <!-- Long Description -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">
            Long Description
          </div>
          <div class="col-md-7">
            <vue-ckedit :disabled="noEdit" v-model="formData.long_description" />
          </div>
        </div>
        <!-- Short Description -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">
            Short Description
          </div>
          <div class="col-md-7">
            <vue-ckedit :disabled="noEdit" v-model="formData.short_description" />
          </div>
        </div>
        <!-- What's in the box -->
        <div class="row mb-2">
          <div class="col-md-2 pt-2">
            What's in the box
          </div>
          <div class="col-md-7">
            <vue-ckedit :disabled="noEdit" v-model="formData.whats_in_the_box" />
          </div>
        </div>
        <div class="shadow mb-5">&nbsp;</div>
        <div v-if="!noEdit" class="text-right">
          <button type="button" class="btn btn-info ml-2" @click="submitData()">Save</button>
          <button type="button" class="btn btn-info ml-2" @click="submitData('new')">Save &amp; Add New</button>
          <button type="button" class="btn btn-info ml-2" @click="submitData('dupe')">Save &amp; Duplicate</button>
          <router-link :to="!isBundleUrl ? '/manage-product' : '/manage-bundle'" class="btn btn-secondary ml-2">Cancel</router-link>
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
import { toaster } from "@/_helpers";
import InputText from "@/components/formCustom/InputText";
import InputSelect from "@/components/formCustom/InputSelect";
import InputDate from "@/components/formCustom/InputDate";
import ProductListNavTab from "@/components/product/ProductListNavTab";
import BundleNavTab from "@/components/bundle/BundleNavTab";
import LoadingOverlay from "@/components/LoadingOverlay";

import moment from "moment";
import { required, requiredIf, integer, decimal, minValue, maxValue, between } from "vuelidate/lib/validators";

const initialData = () => ({
  item: null,
  imgSrc: null,
  imgIndex: null,
  filterBundleCategory: "",
  selected_bundle_category_list: [],
  formData: {
    // main
    name: null,
    category_id: 0,
    sub_category_id: 0,
    sub_sub_category_id: 0,
    brand_id: 0,
    // images: [null, null, null, null, null],
    status_purchaseable: true,
    bundle_check_all_product: false,
    bundle_category_list_ids: null,
    bundle_dynamic_price: false,
    bp_exclusive: false,
    catalog_visible: true,
    price: 0,
    discount: 0,
    discount_type: 0,
    from_date: null,
    to_date: null,
    from_time: null,
    to_time: null,
    reseller_commission_value: 0,
    reseller_commission_type: 0,
    reseller_discount_value: 0,
    reseller_discount_type: 0,
    weight: 0,
    dimension1: null,
    dimension2: null,
    dimension3: null,
    diameter: null,
    number_of_diamond: null,
    diamond_color: null,
    diamond_clarity: null,
    stamp: null,
    certificate: null,
    kadar: null,
    weight_of_gold: null,
    sum_carat_of_gold: null,
    weight_of_gold_plus_diamond: null,
    size_guide: null,
    long_description: null,
    short_description: null,
    whats_in_the_box: null,
    // PO system
    status_stock: true,
    ready_stock_duration: 0,
    batch_po: false,
    start_date_po: null,
    end_date_po: null,
    // hidden
    meta_title: null,
    meta_keyword: null,
    meta_description: null,
    warranty: 0,
    warranty_period: null,
    attribute: {},
  },
  images: [null, null, null, null, null],
})

const discountValidation = function(discount) {
  discount = parseInt(discount)
  const price = parseInt(this.formData.price);
  const discountType = this.formData.discount_type;
  const isBundleDynamicPrice = this.formData.bundle_dynamic_price;

  if (discountType == 0) return true // * No Discount
  if (discountType == 1) return isBundleDynamicPrice ? true : discount <= price // * Nominal
  if (discountType == 2) return discount > 100 ? false : true; // * Percentage
};

export default {
  name: "Product",
  components: {
    InputText,
    InputSelect,
    InputDate,
    ProductListNavTab,
    BundleNavTab,
    LoadingOverlay,
  },
  data() {
    return Object.assign({
      isLoading: false,
      brands: [],
      categories: [],
      sub_categories: [],
      sub_sub_categories: [],
      bundle_category_list: [], // All exisiting level 3 categories (sub sub categories)
      attributes: [],
    }, initialData())
  },
  validations() {
    return {
      formData: {
        name: {
          required
        },
        category_id: {
          required,
          decimal,
          minValue: minValue(this.isBundleUrl ? 0 : 1),
        },
        sub_category_id: {
          required,
          decimal,
          minValue: minValue(this.isBundleUrl ? 0 : 1),
        },
        sub_sub_category_id: {
          required,
          decimal,
          minValue: minValue(this.isBundleUrl ? 0 : 1),
        },
        brand_id: {
          required,
          decimal,
          minValue: minValue(1),
        },
        bp_exclusive: {
          required,
        },
        status_purchaseable: {
          required,
        },
        catalog_visible: {
          required,
        },
        bundle_dynamic_price: {
          required,
        },
        price: {
          required: requiredIf((self) => !self.bundle_dynamic_price),
          decimal,
          minValue: minValue(!this.formData.bundle_dynamic_price ? 10000 : 0),
        },
        discount: {
          required,
          decimal,
          minValue: minValue(0),
          // maxValue: maxValue(this.formData.discount_type == 2 ? 100 : this.formData.price),
          discountValidation
        },
        discount_type: {
          required,
          decimal
        },
        from_date: {},
        to_date: {
          required: requiredIf((self) => self.from_date)
        },
        from_time: {},
        to_time: {
          required: requiredIf((self) => self.from_time)
        },
        reseller_commission_value: {
          required,
          decimal,
          minValue: minValue(0),
          maxValue: maxValue(this.formData.reseller_commission_type == 2 ? 100 : this.formData.bundle_dynamic_price ? 10000000 : this.formData.price)
        },
        reseller_commission_type: {
          required,
          decimal
        },
        reseller_discount_value: {
          required,
          decimal
        },
        reseller_discount_type: {
          required,
          decimal
        },
        weight: {
          required,
          decimal,
          minValue: (value) => value > 0,
          between: between(0, 1000)
        },
        dimension1: {
          decimal
        },
        dimension2: {
          decimal
        },
        dimension3: {
          decimal
        },
        diameter: {
          decimal
        },
        number_of_diamond: {
          decimal
        },
        kadar: {
          decimal
        },
        weight_of_gold: {
          decimal
        },
        sum_carat_of_gold: {
          decimal
        },
        weight_of_gold_plus_diamond: {
          decimal
        },
        // PO system
        ready_stock_duration: {
          required,
          integer,
          minValue: minValue(0)
        },
        batch_po: {
          required
        },
        start_date_po: {
          required: requiredIf((self) => self.batch_po)
        },
        end_date_po: {
          required: requiredIf((self) => self.batch_po)
        },
      },
    }
  },
  computed: {
    productId() {
      return this.$route.params.id;
    },
    noEdit() {
      return this.$route.path.indexOf('/detail') == 0
    },
    isBundleUrl() {
      return this.$route.path.indexOf('bundle') >= 0
    },
    currentView() {
      return this.$route.path.match(/^(\/\w+)/)[1];
    },
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
    filteredBundleCategory: function() {
      return this.bundle_category_list.filter(el => !this.selected_bundle_category_list.find(rel => rel.id == el.id) && (this.filterBundleCategory ? new RegExp(this.filterBundleCategory, "i").test(el.name) : el))
    },
  },
  mounted() {
    if (this.productId) this.getDetails(this.productId);
    // else this.getMasterAttributes();
    this.getBrands();
    this.getCategories();
    this.getBundleCategoryList();
  },
  methods: {
    resetDiscountDateRange() {
      document.getElementById('from-time').value = null;
      // Handle to time value is not resetted after change to disable state
      setTimeout(() => {
        document.getElementById('to-time').value = null;
      }, 100);
      this.formData.to_time = null;
      this.formData.from_time = null;
      this.formData.to_date = null;
      this.formData.from_date = null;
    },
    async getDetails(id) {
      this.isLoading = true;
      try {
        const res = await this.$api.product.getProductDetail(id);
        if (res.status === 200) {
          this.item = res.data.data;
          if (this.isBundleUrl && this.item.product_type != 4) return this.$router.replace(this.currentView + "-product/" + id)
          else if (!this.isBundleUrl && this.item.product_type == 4) return this.$router.replace(this.currentView + "-bundle/" + id)
          this.formData.name = this.item.name
          this.setCategories(this.item.categories1[0].id, this.item.categories2[0].id, this.item.categories[0].id)
          this.formData.brand_id = this.item.brand.id
          this.formData.bundle_dynamic_price = this.item.bundle_dynamic_price
          this.formData.bp_exclusive = this.item.bp_exclusive
          this.formData.status_purchaseable = this.item.status_purchaseable ?? true
          this.formData.catalog_visible = this.item.catalog_visible
          this.formData.bundle_check_all_product = this.item.bundle_check_all_product
          this.setBundleRelatedCategory(this.item.bundle_category_list_ids)
          this.formData.price = this.item.price
          this.formData.discount = this.item.discount
          this.formData.discount_type = this.item.discount_type
          this.formData.from_date = this.item.discount_active_from ? this.formatDate(new Date(this.item.discount_active_from)) : null
          this.formData.to_date = this.item.discount_active_from ? this.formatDate(new Date(this.item.discount_active_to)) : null
          this.formData.from_time = this.item.discount_active_from ? this.formatDate(new Date(this.item.discount_active_from), "HH:mm") : null
          this.formData.to_time = this.item.discount_active_from ? this.formatDate(new Date(this.item.discount_active_to), "HH:mm") : null
          this.formData.reseller_commission_value = this.item.reseller_commission_value ?? 0
          this.formData.reseller_commission_type = this.item.reseller_commission_type
          this.formData.reseller_discount_value = this.item.reseller_discount_value ?? 0
          this.formData.reseller_discount_type = this.item.reseller_discount_type
          this.formData.weight = this.item.product_details[0].weight
          this.formData.dimension1 = this.item.product_details[0].dimension_x
          this.formData.dimension2 = this.item.product_details[0].dimension_y
          this.formData.dimension3 = this.item.product_details[0].dimension_z
          this.formData.diameter = this.item.product_details[0].diameter
          this.formData.number_of_diamond = this.item.product_details[0].number_of_diamond
          this.formData.diamond_color = this.item.product_details[0].diamond_color
          this.formData.diamond_clarity = this.item.product_details[0].diamond_clarity
          this.formData.stamp = this.item.product_details[0].stamp
          this.formData.certificate = this.item.product_details[0].certificate
          this.formData.kadar = this.item.product_details[0].kadar
          this.formData.weight_of_gold = this.item.product_details[0].weight_of_gold
          this.formData.sum_carat_of_gold = this.item.product_details[0].sum_carat_of_gold
          this.formData.weight_of_gold_plus_diamond = this.item.product_details[0].weight_of_gold_plus_diamond
          this.formData.size_guide = this.item.product_details[0].size_guide?.toString()
          this.formData.long_description = this.item.product_details[0].description?.toString()
          const [shortDescription] = this.item.product_details[0].short_description
          this.formData.short_description = shortDescription == "null" ? "" : shortDescription?.toString()
          this.formData.whats_in_the_box = this.item.product_details[0].what_in_the_box?.toString()
          // PO system
          this.formData.status_stock = this.item.pre_order.status_stock
          this.formData.ready_stock_duration = this.item.pre_order.ready_stock_duration
          this.formData.batch_po = this.item.pre_order.batch_po
          this.formData.start_date_po = this.item.pre_order.start_date_po ? this.formatDate(new Date(this.item.pre_order.start_date_po)) : null
          this.formData.end_date_po = this.item.pre_order.end_date_po ? this.formatDate(new Date(this.item.pre_order.end_date_po)) : null
          // Hidden
          this.formData.meta_title = this.item.meta_title
          this.formData.meta_keyword = this.item.meta_keyword
          this.formData.meta_description = this.item.meta_description
          this.formData.warranty = this.item.product_details[0].warranty_type
          this.formData.warranty_period = this.item.product_details[0].warranty_period
          // this.formData.attribute = this.item.product_details[0].attributes_s.map(at => ({[at.group_attribute_id]: at.attribute_id}))
          this.item.product_details[0].attributes_s.forEach(el => {
            this.formData.attribute[el.group_attribute_id] = el.attribute_id
          })
        } else toaster.make(`${res.status} - ${res.data.message}`, "danger");
      } catch (e) {
        console.error(e);
      }
      this.isLoading = false;
    },
    async submitData(type) {
      try {
        this.$v.$touch()
        if (this.$v.$error) {
          this.$helpers.toaster.make('Please fill in the form correctly', 'danger')
          return this.$helpers.console.vuelidateFormLog(this.$v.formData);
        }
        // const images = this.images.filter(el => el);
        const data = JSON.parse(JSON.stringify(this.formData))
        if (this.productId) data.id = this.productId;
        else {
          data.attribute = {"1": "1"}
          const meta = this.formData.name.toLowerCase().split(' ').join('-')
          data.meta_title = data.meta_keyword = data.meta_description = meta
          // if (images.length < 2) return this.$helpers.toaster.make("Please add at least 2 images to proceed", "danger")
          if (this.images.filter(el => el).length < 2) return this.$helpers.toaster.make("Please add at least 2 images to proceed", "danger")
        }
        if (this.isBundleUrl) {
          data.product_type = 4
          data.status_purchaseable = true
          data.price = data.bundle_dynamic_price ? 0 : data.price;
        } else delete data.bundle_dynamic_price
        // Start PO conditioning
        data.status_stock = data.ready_stock_duration > 0 ? false : true
        if (data.status_stock) data.batch_po = false
        if (!data.batch_po) data.start_date_po = data.end_date_po = null
        // End PO conditioning
        data.short_description = [data.short_description]

        // Discount conditioning
        data.discount = data.discount_type == 0 ? 0 : data.discount
      
        if (this.selected_bundle_category_list.length > 0) data.bundle_category_list_ids =  "[" + this.selected_bundle_category_list.map(el => el.id).join(",") + "]"

        const form = new FormData();
        form.append("data", JSON.stringify(data));
        for (const i in this.images) {
          if (this.images[i]) form.append(`image_${parseInt(i) + 1}`, this.images[i]);
        }

        this.isLoading = true;
        const res = await this.$api.product.saveProduct(form);
        if (res.status === 200) {
          this.$helpers.toaster.make(res.data.message, 'success')
          if (type == 'new') this.clearData(!this.isBundleUrl ? '/add-product' : '/add-bundle')
          else if (type == 'dupe' && this.$route.path.indexOf('add') < 0) this.$router.replace({path: !this.isBundleUrl ? '/add-product' : '/add-bundle', query: {save: null}})
          else this.$router.push(!this.isBundleUrl ? '/manage-product' : '/manage-bundle')
        }
        else this.$helpers.toaster.make(res.data.message, 'danger')
        this.isLoading = false;
      } catch (e) {
        console.error(e)
        this.isLoading = false
      }
    },
    clickImgInput(index) {
      document.getElementById('img-input-' + index).click()
    },
    setPreviewImage(e, index) {
      this.images[index] = e.target.files[0];
      const file = e.target.files[0];
      if (!file.type.includes('image/')) return this.$helpers.toaster.make("Please select an image file", "danger");
      else if (Math.round(file.size / 1000) > 512) return this.$helpers.toaster.make("File size must not exceed 512 KB", "danger")

      // check if file reader is supported
      else if (typeof FileReader === 'function') {
        const img = new Image();
        let imgWidth, imgHeight;
        img.src = window.URL.createObjectURL(file);
        img.onload = (e) => {
					if (e['path']) { // Chromium
						imgWidth = e['path'][0].naturalWidth;
						imgHeight = e['path'][0].naturalHeight;
					} else if (e['originalTarget']) { // Firefox
						imgWidth = e['originalTarget'].naturalWidth;
						imgHeight = e['originalTarget'].naturalHeight;
          } else {
            imgWidth = img.width;
            imgHeight = img.height;
          }

          //? Image width dimension validation
          if (imgWidth < 2000 || imgHeight < 2000) {
            this.$helpers.toaster.make(`Image must be at least 2000px x 2000px`, "danger");
            return
          }
        }

        this.imgIndex = index;
        const reader = new FileReader();
        reader.onload = (event) => {
          //* Product Thumbnail Image Preview
          const el = document.getElementById(`img-preview-${index}`);
          this.imgSrc = el.src = event.target.result;
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    setImage(imgIndex) {
      const imgType = document.querySelector(`#img-input-${imgIndex}`).files[0].type;

      if (!this.$refs.cropper?.getCroppedCanvas()) 
        return setTimeout(() => {
          this.setImage(imgIndex);
        }, 100);
      
      this.$refs.cropper.getCroppedCanvas({
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      }).toBlob(blob => {
        const file = new File([blob], `product-img-${imgIndex}` + "." + blob.type.split("/")[1], blob);
        this.images.splice(imgIndex, 1, file);
      }, imgType, .85);
    },
    cropend(imgIndex) {
      const el = document.getElementById('img-preview-' + imgIndex)
      el.src = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.8)
    },
    setCategories(catId, subCatId, subSubCatId) {
      if (this.categories.length == 0) setTimeout(() => this.setCategories(catId, subCatId, subSubCatId), 100)
      else {
        this.formData.category_id = catId
        this.formData.sub_category_id = subCatId
        this.formData.sub_sub_category_id = subSubCatId
        this.sub_categories = this.categories.find(el => el.id == catId).sub_category
        this.sub_sub_categories = this.sub_categories.find(el => el.id == subCatId).sub_category
      }
    },
    setBundleRelatedCategory(categoryIds) {
      if (!categoryIds) return
     
      if (this.bundle_category_list.length == 0) return setTimeout(() => {
        this.setBundleRelatedCategory(categoryIds)
      }, 500); 

      const categoryListIds = categoryIds.match(/\[(.+)\]/)[1].split(",").map(el => parseInt(el))
      this.selected_bundle_category_list = this.bundle_category_list.filter(el => categoryListIds.find(rel => rel == el.id))
    },
    async getBrands() {
      try {
        const res = await (this.merchantData.merchant_id == 48 ? this.$api.cms.getAllBrands() : this.$api.cms.getBrands());
        if (res.status === 200) this.brands = this.merchantData.merchant_id == 48 ? res.data.data : res.data.data.map(el => el.brand);
        else toaster.make(`${res.status} - ${res.data.message}`, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    async getCategories() {
      try {
        const res = await this.$api.cms.getCategories();
        if (res.status === 200) this.categories = res.data.data;
        else toaster.make(`${res.status} - ${res.data.message}`, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    getSubCategory(id) {
      this.formData.sub_category_id = 0
      this.formData.sub_sub_category_id = 0
      this.sub_categories = this.categories.find(el => el.id == id).sub_category
      this.sub_sub_categories = []
    },
    getSubSubCategory(id) {
      this.formData.sub_sub_category_id = 0
      this.sub_sub_categories = this.sub_categories.find(el => el.id == id).sub_category
    },
    async getMasterAttributes() {
      try {
        const res = await this.$api.product.getAttributeList();
        if (res.status === 200) this.attributes = res.data.data;
        else toaster.make(`${res.status} - ${res.data.message}`, "danger");
      } catch (e) {
        console.error(e);
      }
    },
    async getBundleCategoryList() {
      try {
        const res = await this.$api.product.getProductSizeCategories(); // to get all level 3 categories, we can use this existing endpoint
        if (res.status === 200) this.bundle_category_list = res.data.data.map(el => ({id: el.id, name: el.name}))
      } catch (error) {
        console.error(error);
      }
    },
    getContainerWidth(el) {
      const element = document.querySelector(el)
      return element ? element.offsetWidth : null
    },
    addBundleRelatedCategory(category) {
      this.selected_bundle_category_list.push(category);
      this.filterBundleCategory = "";
    },
    removeBundleRelatedCategory(category) {
      this.selected_bundle_category_list.splice(this.selected_bundle_category_list.findIndex(el => el.id == category.id), 1)
    },
    checkImg(value) {
      return value ? value : require('@/assets/img/icon-upload.png')
    },
    formatDate(value, format = "YYYY-MM-DD") {
      return value ? moment(String(value)).format(format).toString() : value
    },
    clearData(destination) {
      this.$router.replace({path: '/reload', query: {next: destination}})
    },
     //* Prevent "." (dot) keyboard input on keypress event
    preventDotInput(e, runValidation = true) {
      if (e.charCode == 46 && runValidation) e.preventDefault();
    },
  },
  watch: {
    $route(to) {
      const { path, params, query } = to
      if (path.indexOf('/add') == 0 && query.save === undefined) this.clearData(path)
      else if (path.indexOf('/detail') == 0) this.getDetails(params.id)
    },
    "formData.bundle_check_all_product"(val) {
      if (!val || this.selected_bundle_category_list.length == 0) return
      this.$bvModal.msgBoxConfirm(
          "Are you sure want to set bundling to all products? Selected bundling related category list will be deleted",
          {
            okTitle: "Yes",
            cancelTitle: "No",
            centered: true,
          }
        ).then((val) => {
          if(!val) return this.formData.bundle_check_all_product = false 
          this.selected_bundle_category_list = []
        }).catch(() => this.formData.bundle_check_all_product = false)
    },
    "formData.discount_type"(val) {
      //* Remove decimal value for 'formData.discount' if 'formData.discount_type' == 1 (nominal)
      if (val == 1 && this.formData.discount) this.formData.discount = Math.trunc(this.formData.discount);
      if (val == 0) {
        this.formData.from_date = null;
        this.formData.to_date = null;
        this.formData.from_time = null;
        this.formData.to_time = null;
      }
    }
  }
};
</script>
<style scoped>
/* Category tag custom */
.category-tag-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}
.category-tag-container .tag {
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: .25rem;
  background: #f2f2f2;
}
.tag .delete-tag {
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
}
.category-tag-container input {
  flex-basis: 100%;
  padding: 5px;
  outline: none;
  border: 0;
}
/* Category tag no edit */
.category-tag-container.disabled :is(input, .delete-tag) {
  display: none;
}
/* Category options */
.category-options {
  position: absolute;
  border: 1px solid #ced4da;
  border-radius: .5rem;
  background: white;
  overflow-y: auto;
  visibility: collapse;
  width: 100%;
  max-height: 256px;
  opacity: 0;
  transition: 0s all;
  z-index: 9;
  margin: 0 -8px;
  top: 101%;
}
.category-tag-container input:focus + .category-options {
  opacity: 1;
  visibility: visible;
}
.category-options > div {
  padding: .5rem;
}
.category-options > div:not(.no-hover) {
  cursor: pointer;
}
.category-options > div:not(.no-hover):hover {
  background: #ced4da;
}
</style>
